export enum Schy {
  all = 'すべて選択',
  yearOld0 = '０歳',
  yearOld1 = '１歳',
  yearOld2 = '２歳',
  presch1st = '年少',
  presch2nd = '年中',
  presch3rd = '年長',
  elStu1st = '小学1年',
  elStu2nd = '小学2年',
  elStu3rd = '小学3年',
  elStu4th = '小学4年',
  elStu5th = '小学5年',
  elStu6th = '小学6年',
  miStu1st = '中学1年',
  miStu2nd = '中学2年',
  miStu3rd = '中学3年',
  hiStu1st = '高校1年',
  hiStu2nd = '高校2年',
  hiStu3rd = '高校3年',
  general = '一般'
}
